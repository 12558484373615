import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://0dcb0f8c4db9247ee65de077b369b114@o4507667223609344.ingest.de.sentry.io/4507667235864656",
  debug: false,
  environment: "production",
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});